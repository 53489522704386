<template>
  <div class="card-partner">
    <div class="card-header">
      <div class="partner-type">{{ type }}</div>
      <div class="card-header--content display--flex align-item-center">
        <router-link v-if="imagePath" :to="link">
          <img class="partner-image" :src="`${backURL}/${imagePath}`" alt="" />
        </router-link>
        <div class="card-header--meta">
          <router-link :to="link" class="partner-name">{{ name }}</router-link>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div
        class="
          card-body--meta
          display--flex
          justify-content-between
          align-item-center
        "
      >
        <div class="display--flex justify-content-start align-item-center">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.9998 5.00068C13.3254 5.00213 14.5961 5.52929 15.5333 6.4665C16.4706 7.40371 16.9979 8.67443 16.9993 9.99988C16.9993 13.5833 12.9982 17.6496 11.9998 18.6082C11.002 17.6502 7.00068 13.5839 7.00068 9.99988C7.00218 8.67447 7.52936 7.40378 8.46657 6.46657C9.40378 5.52936 10.6745 5.00218 11.9998 5.00068ZM11.9998 4C10.4092 4.00184 8.88415 4.63455 7.75935 5.75935C6.63455 6.88415 6.00183 8.40918 6 9.99988C6 14.5114 11.3269 19.3578 11.5536 19.5617L11.9998 19.9633L12.4461 19.5617C12.6729 19.3578 18 14.5116 18 9.99988C17.9981 8.40915 17.3654 6.88411 16.2405 5.75932C15.1157 4.63452 13.5906 4.00182 11.9998 4Z"
            />
            <path
              d="M12.0002 8.76991C12.2443 8.76991 12.4829 8.84229 12.6858 8.9779C12.8888 9.11351 13.047 9.30626 13.1404 9.53178C13.2338 9.7573 13.2582 10.0054 13.2107 10.2449C13.163 10.4843 13.0455 10.7042 12.8728 10.8767C12.7002 11.0494 12.4804 11.1669 12.2409 11.2146C12.0016 11.2621 11.7534 11.2377 11.5279 11.1443C11.3024 11.0509 11.1096 10.8927 10.974 10.6897C10.8384 10.4868 10.766 10.2482 10.766 10.0041C10.7664 9.67687 10.8965 9.36317 11.1279 9.1318C11.3593 8.90042 11.6729 8.77028 12.0002 8.76991ZM12.0002 7.83594C11.5713 7.83594 11.1522 7.9631 10.7956 8.20133C10.4391 8.43957 10.1612 8.77819 9.99707 9.17437C9.83297 9.57054 9.79003 10.0065 9.87369 10.4271C9.95735 10.8477 10.1638 11.234 10.4671 11.5372C10.7703 11.8404 11.1566 12.0469 11.5772 12.1306C11.9978 12.2143 12.4337 12.1713 12.8299 12.0072C13.2261 11.8431 13.5647 11.5652 13.8029 11.2086C14.0412 10.8521 14.1683 10.4329 14.1683 10.0041C14.1683 9.42905 13.9399 8.87758 13.5333 8.47097C13.1267 8.06437 12.5752 7.83594 12.0002 7.83594Z"
            />
          </svg>
          {{ city }}
        </div>
        <div
          v-if="experts"
          class="display--flex justify-content-start align-item-center"
        >
          <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.9918 6.93382C14.57 6.93382 13.4325 8.07516 13.4325 9.49318C13.4325 10.9112 14.5738 12.0525 15.9918 12.0525C17.4137 12.0525 18.5512 10.892 18.5512 9.49318C18.555 8.07516 17.3907 6.93382 15.9918 6.93382ZM15.9918 10.9804C15.1733 10.9804 14.5085 10.3117 14.5085 9.49702C14.5085 8.68233 15.1772 8.01367 15.9918 8.01367C16.8065 8.01367 17.4752 8.68233 17.4752 9.49702C17.4752 10.3117 16.8104 10.9804 15.9918 10.9804ZM10.0162 6C8.53281 6 7.32614 7.20667 7.32614 8.69002C7.32614 10.1734 8.53281 11.38 10.0162 11.38C11.4995 11.38 12.7062 10.1734 12.7062 8.69002C12.7062 7.20667 11.4995 6 10.0162 6ZM10.0162 10.304C9.1323 10.304 8.40215 9.57388 8.40215 8.69002C8.40215 7.80615 9.1323 7.07601 10.0162 7.07601C10.9 7.07601 11.6302 7.80615 11.6302 8.69002C11.6302 9.57388 10.9 10.304 10.0162 10.304ZM19.5427 18.0129C19.2429 18.0205 18.9931 17.7746 18.9931 17.4748V15.2959C18.9931 14.5005 18.3475 13.8318 17.529 13.8318H15.3885C15.0964 13.8318 14.8467 13.6051 14.839 13.3092C14.8313 13.0017 15.0695 12.7558 15.377 12.7558H17.529C18.924 12.7558 20.0691 13.8971 20.0691 15.2959V17.4748C20.0691 17.7669 19.8347 18.0052 19.5427 18.0129ZM14.3432 17.4595V14.585C14.3432 13.117 13.1443 11.918 11.6763 11.918H8.33298C6.865 11.918 5.66602 13.117 5.66602 14.585V17.4479C5.66602 17.7438 5.90812 17.986 6.20402 17.986C6.49992 17.986 6.74202 17.7438 6.74202 17.4479V14.585C6.74202 13.705 7.4568 12.994 8.33298 12.994H11.6993C12.5794 12.994 13.2903 13.7088 13.2903 14.585V17.4595C13.2903 17.7515 13.5247 17.986 13.8168 17.986C14.1088 17.986 14.3432 17.7515 14.3432 17.4595Z"
            />
          </svg>
          {{ experts }} {{ expertsCount }}
        </div>
      </div>
      <p class="partner-text" v-if="address">
        {{ address }}
      </p>
      <div class="partners-contacts" v-if="email || telephone">
        <p v-if="telephone">{{ telephone }}</p>
        <p v-if="email">{{ email }}</p>
      </div>
      <div class="btn-action display--flex">
        <router-link :to="link" class="btn btn-o btn-sm">Подробнее</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, toRef } from "@vue/runtime-core";
export default {
  name: "partner-card",
  inject: ["backUrl"],

  setup(props) {
    const expertsVar = toRef(props, "experts");
    const backURL = process.env.VUE_APP_LK_BACK;
    const expertsCount = computed(() => {
      if (
        (expertsVar.value % 10 < 5 &&
          expertsVar.value % 10 > 0 &&
          expertsVar.value % 100 < 5) ||
        expertsVar.value % 100 > 20
      ) {
        if (expertsVar.value % 10 > 1) {
          return "эксперта";
        }
      } else {
        return "экспертов";
      }

      return "эксперт";
    });

    return {
      expertsCount,
      backURL,
    };
  },

  props: {
    type: {
      type: String,
      default: "Эксперт",
    },
    imagePath: {
      type: String,
    },
    name: {
      type: String,
      required: true,
    },
    city: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      required: true,
    },
    address: {
      required: false,
    },
    telephone: {
      type: String,
    },
    email: {
      type: String,
    },
    experts: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/partners";
</style>