<template>
  <div class="overflow--auto">
    <div
      @click="isOpen = !isOpen"
      :class="`display ${selectedParams.length ? 'selected' : ''}`"
    >
      <div v-if="!selectedParams.length" class="placeholder">
        {{ placeholder }}
      </div>
      <div
        v-for="[key, param] of Object.entries(selectedParams)"
        :key="key"
        class="selected-item"
        @click.stop="
          () => {
            delete selectedParams[key];
            context.emit('selectParam', Object.keys(selectedParams));
          }
        "
      >
        <span>{{ param }}</span>
      </div>
    </div>
    <ul v-if="isOpen" class="list" @mouseleave="isOpen = false">
      <li
        v-for="option of options"
        :key="option.id"
        @click="
          () => {
            selectedParams[option.id] = option.name;
            context.emit('selectParam', Object.keys(selectedParams));
          }
        "
      >
        {{ option.name }}
      </li>
    </ul>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  name: "custom-select",
  props: {
    options: {
      type: Array,
      required: true,
    },
    placeholder: {
      type: String,
      required: true,
    },
  },
  setup(props, context) {
    const selectedParams = ref({});
    const isOpen = ref(false);

    return {
      selectedParams,
      isOpen,
      context,
    };
  },
};
</script>

<style lang="scss" scoped>
.display {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 120%;
  border: 1px solid #d6d7dd;
  border-radius: 45px;
  padding: 8px 26px 8px 16px;
  display: inline-flex;
  gap: 15px;
  cursor: pointer;
  position: relative;
  text-align: center;
  color: #343645;

  &.selected {
    border-color: var(--accent);
  }

  &::after {
    position: absolute;
    display: block;
    content: "";
    top: 50%;
    right: 12px;
    width: 0;
    height: 0;
    border-width: 3px 3px 0 3px;
    border-style: solid;
    border-color: var(--color) transparent transparent transparent;
    transition: all 0.3s ease-in;
  }
}

.selected-item {
  padding-right: 20px;
  position: relative;

  span {
    max-width: 140px;
    text-overflow: ellipsis;
    overflow: hidden;
    max-height: 22px;
    display: block;
    text-align: start;
    white-space: nowrap;
  }

  &::after {
    content: "";
    top: 2px;
    right: 6px;
    display: block;
    position: absolute;
    width: 1px;
    height: 18px;
    transform: rotate(45deg);
    background-color: #343645;
  }

  &::before {
    content: "";
    top: 2px;
    right: 6px;
    display: block;
    position: absolute;
    width: 1px;
    height: 18px;
    transform: rotate(-45deg);
    background-color: #343645;
  }
}

.list {
  list-style: none;
  padding: 0;
  position: absolute;
  border: 1px solid #d6d7dd;
  border-radius: 15px;
  padding: 8px 0px;
  background-color: white;
  margin-top: 10px;

  li {
    cursor: pointer;
    padding: 4px 15px;
    font-size: 18px;
    word-break: break-word;
    max-width: 340px;
  }

  li:hover {
    color: var(--accent);
  }
}
</style>